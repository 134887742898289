import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


import {
  Partner_one,
  Partner_two,
  Partner_three,
  offshore,
  logos1,
  logos2,
  logos3,
  logos4,
  logos5,
  logos6,
  logos7,
  logos8,
  logos9,
  logos10,
  logos11,
  logos12,
  logos13,
  logos14,
  logos15,
  logos16,
  logos17,
} from "../Assets/Images/Images";

const Partners = () => {
 


  return (
    <>
    <div className='my-4'>
      <div className="  mt-lg-0 py-4 py-lg-0 ">
        <div className="container">
          <div className="row position-relative " >
            <div className="col-12">
              <h1 className="  text-center text-white">
                <b>Partner <strong style={{ color: "#FFA200" }}>Companies</strong>
                </b>
              </h1>
            </div>
          </div>


        </div>
      </div>
      <div className='container position-relative'>

        <div class=" row  d-flex justify-content-center">
          <div class="col-md col-12 mb-lg-0 mb-4 d-flex justify-content-md-end justify-content-center  ">
            <div class="shadow  bg-white card_logo border-0 p-4">
              <img
                className="w-100  my-auto h-75"
                src={Partner_two}
                alt="Partner_two"
              />
              <div className="mt-2 border-top border-1 mx-4 p-2">
                <p>
                  <i
                    class="bi bi-geo-alt-fill"
                    style={{ color: "rgb(255, 162, 0)" }}
                  ></i>{" "}
                  <b> Richfield, OH, USA</b>
                </p>
              </div>
            </div>
          </div>
          <div class="col-md col-12 d-flex justify-content-md-start justify-content-center " >
            <div class="shadow  bg-white card_logo border-0 p-4 ">
              <img
                className="w-100 my-auto pt-2  h-75"
                src={Partner_one}
                alt="Partner_one"
              />
              <div className="mt-2 border-top border-1 mx-4 mb-2 py-2">
                <p>
                  <i
                    class="bi bi-geo-alt-fill"
                    style={{ color: "rgb(255, 162, 0)" }}
                  ></i>{" "}
                  <b> Chevy Chase, MD, USA</b>
                </p>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="mt-5">
        <div className="container">
          <div className="row position-relative " >
            <div className="col-12">
              <h1 className=" mb-5 text-center text-white">
                <b>Associated <strong style={{ color: "#FFA200" }}>With</strong>
                </b>
              </h1>
            </div>
          </div>


        </div>


      
        <section className='slider-wrapper  position-relative'>
          <div className='slider-track'>

            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos1} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos1} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos2} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos3} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos4} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos5} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos6} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos7} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos8} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos9} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos10} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos11} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos12} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos13} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos14} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos15} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos16} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos17} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos1} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos1} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos2} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos3} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos4} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos5} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos6} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos7} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos8} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos9} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos10} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos11} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos12} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos13} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos14} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos15} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos16} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos17} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos1} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos1} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos2} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos3} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos4} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos5} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos6} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos7} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos8} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos9} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos10} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos11} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos12} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos13} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos14} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos15} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos16} /></div>
            <div className="slide mx-4  d-flex"><img className='w-100 bg-white mx-2 ' src={logos17} /></div>

          </div>
        </section>
      </div>
      </div>
    </>

  );
};

export default Partners;

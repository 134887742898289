import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  image1,
  image2,
  image3,
  Equipment,
  india,
  health,
  automobile,
  asset,
  parma,
} from "../Assets/Images/Images";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const CardNews = () => {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    speed: 500,
    autoplaySpeed: 1500,
    autoplay: true,
    centerMode: true,
    centerPadding: "0",
    focusOnSelect: true,
  };
  const [activeTab, setActiveTab] = useState(0);
  const [btnClicked, setBtnClicked] = useState(false);
  useEffect(() => {
    const tabSwitchInterval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % 4); // Assuming there are 4 tabs
    }, 4000); // Switch tab every 5 seconds
    if (btnClicked) {
      clearInterval(tabSwitchInterval);
    }
    return () => (clearInterval(tabSwitchInterval));
  }, [btnClicked]);

  return (
    <>
      {/* <div className="container d-md-none d-block">
        <div className=" mt-5 ">
          <h1 className=" text-white  "><b>  Our{" "}
            <span style={{ color: "#FFA200" }} className="fw-bolder">
              Services
            </span></b></h1>
          <div className="card card_data px-0 col w-100  red" >
            <div className="add_img_height_width w-100 ">
              <img src={asset} alt="Slide 1" className="card-img" />
              <div className=" position-absolute top-0 w-100 py-5 section_card1">
                <h2 className="text-white px-3 asset-heading "><b>Asset Management</b></h2>{" "}

              </div>
            </div>
            <div className="position-absolute w-100 section_card text-decoration-none" >
              <div className="add_img_height_width_text  d-flex align-items-center" >
                <div className="mx-auto">

                  <div>
                    <div className='text-start  text-white position-relative p-lg-5'>
                      <h1 className='mb-3 card-hover-heading' style={{ color: 'rgb(255, 162, 0)' }}><b> Asset management:</b></h1>
                      <h5 className='text_justifly card-hover-text' ><b>We buy - We sell – We deploy - We Manage & Maintain – We Upgrade – We dispose when time comes in the most cost-efficient manner.
                        The goal of asset management is to maximize the value of an investment portfolio over time while maintaining an acceptable level of risk.</b></h5>
                      <h5 className='my-4 card-hover-head' style={{ color: 'rgb(255, 162, 0)' }}> <b>Key Takeaways:</b></h5>
                      <ul className='px-5 fs-5'>
                        <li className="card-hover-para"><b>We maximize the value of an investment portfolio over time while maintaining an acceptable level of risk.</b></li>
                        <li className="card-hover-para"><b>Our team have a fiduciary responsibility to their clients to act in their best interests.</b></li>
                        <li className="card-hover-para"><b>We make decisions on behalf of the clients and are required to do so in good faith.</b></li>
                        <li className="card-hover-para"><b>We increase the value while mitigating risk. Tolerance for risk is one of the first topics that an asset manager might raise with a client.</b></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card card_data px-0 col blue">
            <div className="add_img_height_width">
              <img
                src={automobile}
                alt="Slide 1"
                className=""
                style={{ width: '100%', height: '100%', filter: 'brightness(75%)' }}
              />
              <div className=" position-absolute top-0 w-100 py-5 section_card1">
                <h2 className="text-white px-3 asset-heading "><b>Parts Management</b></h2>{" "}

              </div>
            </div>

            <div className="position-absolute section_card text-decoration-none" >
              <div className="add_img_height_width_text  d-flex align-items-center">
                <div className="mx-auto">

                  <div >
                    <div div className=' container  d-flex align-items-center p-md-5  px-0 py-5 ' >
                      <div className="text-start text-white position-relative">
                        <h1 className='mb-3 card-hover-heading' style={{ color: 'rgb(255, 162, 0)' }}><b>Parts Management:</b></h1>
                        <h5 className='text_justifly pb-3 card-hover-text  '  ><b style={{ color: 'rgb(255, 162, 0)' }}> Rowth BMG</b> <b> deals in all type of Automobile spare parts inventory management which involves - overseeing the procurement – storage – tracking & distribution of spare parts to ensure availability when needed and minimize costs associated with excess or insufficient inventory. We deal right from small, medium & premium car industry to Heavy construction & mining equipment spare parts of any type, shape or model.</b></h5>
                        <h5 className='text_justifly pb-3 card-hover-para card_para' style={{ wordSpacing: '8px' }}  ><b>We through our global presence and direct association with all the major & small automobile brands and there distribution network globally efficiently satisfy our customer base globally. We deals in 3rd party manufacturing & supply chain management for all brand Genuine & After-Market spare parts at the cheapest pricing.</b></h5>


                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
          <div className="card card_data px-0 col green">

            <div className="add_img_height_width ">
              <img src={Equipment} alt="Slide 1" className="" style={{ width: '100%', height: '100%', filter: 'brightness(75%)' }} />
              <div className=" position-absolute top-0 w-100 py-5 section_card1">
                <h2 className="text-white px-3 asset-heading "><b>Construction & Mining Equipment</b></h2>{" "}

              </div>
            </div>

            <div className="position-absolute section_card text-decoration-none">
              <div className="add_img_height_width_text  d-flex align-items-center">
                <div className="mx-auto">

                  <div >
                    <div className="container d-flex align-items-center text-start  text-white position-relative p-md-5  px-0 py-5">
                      <div className='position-relative'>
                        <h1 className='mt-5 pt-md-5 pt-0 card-hover-heading' style={{ color: 'rgb(255, 162, 0)' }}><b>Construction & Mining Equipment: (Used/New)</b></h1>
                        <h5 className='text_justifly card-hover-text'><b style={{ color: 'rgb(255, 162, 0)' }}> Rowth BMG</b><b> is one stop solution to all your requirements related to Heavy Construction & Mining Equipment’s. We deal in all Brands - Used & New - Buying & Selling, anywhere in the globe. Our Global presence and direct dealing with the end consumer make us the 1st choice for all our buyers & sellers. Direct arrangements with all the big mining companies across globe make us the unique choice for all our customers.</b></h5>
                        <h5 className='text_justifly card-hover-para' style={{ lineHeight: '35px', wordSpacing: '8px' }}  > <b> Our motto</b> - <b style={{ color: 'rgb(255, 162, 0)' }}>Value for Money </b><b>is what differentiate us from the competition.</b></h5>

                      </div>
                    </div>                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="made card card_data px-0 col green">
            <div className="add_img_height_width ">
              <img src={india} alt="Slide 1" className="" style={{ width: '100%', height: '100%', filter: 'brightness(75%)' }} />
              <div className=" position-absolute top-0 w-100 py-5 section_card1">
                <h2 className="text-white px-3 asset-heading "><b>Make in india</b></h2>{" "}

              </div>
            </div>

            <div className="position-absolute section_card text-decoration-none" >
              <div className="add_img_height_width_text  d-flex align-items-center">

                <div className="mx-auto">
                  <div>
                    <div className="container text-start d-flex align-items-center text-white px-md-5 px-0"  >
                      <div className='position-relative'>
                        <h1 className='my-4 card-hover-heading' style={{ color: 'rgb(255, 162, 0)' }} ><b>Make in India:</b></h1>
                        <h5 className='text_justifly card-hover-text' ><b>Make in India' initiative was launched globally in September 2014 as a part of India's renewed focus on Manufacturing with the objective to promote India as the most preferred global manufacturing destination. The 4 pillars of Make in India initiative are New Mindset, New Sectors, New Infrastructure, and New Processes.</b> </h5>
                        <h4 className='my-4 ' style={{ color: 'rgb(255, 162, 0)' }}> <b>Benefits of Make in India</b></h4>
                        <ul className='px-lg-5 px-2 fs-5' style={{ wordSpacing: '8px' }} >
                          <li className="card-hover-para"><b>Reduction in imports and increased export opportunities.</b></li>
                          <li className="card-hover-para"><b>Promotion of Indian-made products.</b></li>
                          <li className="card-hover-para"><b>Acceptability and preference to Indian made products across Globe.</b></li>
                          <li className="card-hover-para"><b>Attracts industry Incentives through Govt. various schemes to dedicated investors into manufacturing. </b></li>
                        </ul>
                        <div className="d-md-block d-none">
                          <div className="row ">
                            <div className="col">
                              <h4 className='my-0 my-md-4 card-hover-para ' style={{ color: 'rgb(255, 162, 0)' }} > <b>Automobile Industry:</b></h4>
                              <h5 className='mt-md-4 mt-1 card-hover-para' > <b>Racks</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para' > <b>Transmission Components</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Axle Shafts & Brakes</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Hydraulic Components</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Hubs & Spindles</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Differential Housing & Parts</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Sheet Metal Fabrication, Floors & Machining Components</b></h5>
                            </div>
                            <div className="col">
                              <h4 className='my-md-4 my-0 card-hover-para' style={{ color: 'rgb(255, 162, 0)' }}> <b>Industry We Deal:</b></h4>
                              <h4 className='my-md-4 my-0 card-hover-para' style={{ color: 'rgb(255, 162, 0)' }}> <b>Warehousing:</b></h4>
                              <h5 className='mt-md-4 my-0 mb-3 card-hover-para ' > <b>Pallet Racking Solutions</b></h5>
                              <h5 className=' card-hover-para ' > <b>Heavy Duty Racking System</b></h5>
                            </div>




                          </div>
                        </div>
                        <div className=" d-md-none d-block">
                          <div className="d-flex mt-4 ">
                            <div className="">
                              <h4 className='my-0 my-md-4 card-hover-para ' style={{ color: 'rgb(255, 162, 0)' }} > <b>Automobile Industry:</b></h4>
                              <h5 className='mt-md-4 mt-1 card-hover-para' > <b>Racks</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para' > <b>Transmission Components</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Axle Shafts & Brakes</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Hydraulic Components</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Hubs & Spindles</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Differential Housing & Parts</b></h5>
                              <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Sheet Metal Fabrication, Floors & Machining Components</b></h5>
                            </div>
                            <div className="">
                              <h4 className='my-md-4 my-0 card-hover-para' style={{ color: 'rgb(255, 162, 0)' }}> <b>Industry We Deal:</b></h4>
                              <h4 className='my-md-4 my-0 card-hover-para mb-2' style={{ color: 'rgb(255, 162, 0)' }}> <b>Warehousing:</b></h4>
                              <h5 className='mt-md-4 my-0 mb-3 card-hover-para ' > <b>Pallet Racking Solutions</b></h5>
                              <h5 className=' card-hover-para ' > <b>Heavy Duty Racking System</b></h5>
                            </div>




                          </div>
                        </div>


                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div className="cards row col-12 mx-auto mt-4 d-flex justify-content-center">

        </div>
      </div> */}
      <div className="container position-relative my-4 ">
      <h1 className=" text-white  "><b>  Our{" "}
            <span style={{ color: "#FFA200" }} className="fw-bolder">
              Services
            </span></b></h1>

        <div className="container-fluid px-0">
          <div className="d-flex flex-column flex-md-row">
            <ul className="nav nav-tabs p-0 border-0 d-flex flex-column flex-md-row justify-content-between position-relative m-0 w-100" id="myTab" role="tablist" style={{ background: "hsla(0, 0%, 0%, 0.5)", zIndex: "12" }}>
              <li className="nav-item m-0 fs-5" role="presentation">
                <button onClick={() => { setBtnClicked(true) }} className={`nav-link ${activeTab === 0 ? "active" : ""} text-white`} id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane" type="button" role="tab" aria-controls="home-tab-pane" aria-selected={activeTab === 0}>Asset Management</button>
              </li>
              <li className="nav-item m-0 fs-5" role="presentation">
                <button onClick={() => { setBtnClicked(true) }} className={`nav-link ${activeTab === 1 ? "active" : ""} text-white`} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane" type="button" role="tab" aria-controls="profile-tab-pane" aria-selected={activeTab === 1}>Parts Management</button>
              </li>
              <li className="nav-item m-0 fs-5" role="presentation">
                <button onClick={() => { setBtnClicked(true) }} className={`nav-link ${activeTab === 2 ? "active" : ""} text-white`} id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane" type="button" role="tab" aria-controls="contact-tab-pane" aria-selected={activeTab === 2}>Construction & Mining Equipment</button>
              </li>
              <li className="nav-item m-0 fs-5" role="presentation">
                <button onClick={() => { setBtnClicked(true) }} className={`nav-link ${activeTab === 3 ? "active" : ""} text-white`} id="abled-tab" data-bs-toggle="tab" data-bs-target="#abled-tab-pane" type="button" role="tab" aria-controls="abled-tab-pane" aria-selected={activeTab === 3}>Make in India</button>
              </li>
            </ul>
          </div>
        </div>

        <div className="tab-content px-0 container" id="myTabContent">
          <div className={`tab-pane fade ${activeTab === 0 ? "active show" : ""}`} id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab">
            <div className=" position-relative">
              <img src={asset} alt="Slide 1" className="w-100 mobile_img" />
              <div className="position-absolute top-0 w-100  section_card1" style={{ backdropFilter: 'blur(4px)' }}>
                <div className="container">
                  <div className='text-start mt-5 text-white position-relative p-3 p-md-5'>
                    <h1 className='mb-3 card-hover-heading' style={{ color: 'rgb(255, 162, 0)' }}><b>Asset management:</b></h1>
                    <h5 className='text_justifly card-hover-text'><b>We buy - We sell – We deploy - We Manage & Maintain – We Upgrade – We dispose when time comes in the most cost-efficient manner. The goal of asset management is to maximize the value of an investment portfolio over time while maintaining an acceptable level of risk.</b></h5>
                    <h5 className='my-4 card-hover-head' style={{ color: 'rgb(255, 162, 0)' }}> <b>Key Takeaways:</b></h5>
                    <ul className='px-0 px-md-5 fs-5'>
                      <li className="card-hover-para"><b>We maximize the value of an investment portfolio over time while maintaining an acceptable level of risk.</b></li>
                      <li className="card-hover-para"><b>Our team has a fiduciary responsibility to their clients to act in their best interests.</b></li>
                      <li className="card-hover-para"><b>We make decisions on behalf of the clients and are required to do so in good faith.</b></li>
                      <li className="card-hover-para"><b>We increase the value while mitigating risk. Tolerance for risk is one of the first topics that an asset manager might raise with a client.</b></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={`tab-pane fade ${activeTab === 1 ? "active show" : ""}`} id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab">
            <div className="">
              <img
                src={automobile}
                alt="Slide 1"
                className="mobile_img"
                style={{ width: '100%', filter: 'brightness(75%)' }}
              />
              <div className=" position-absolute  px-4 d-flex align-item-center top-0 w-100 py-5 section_card1" style={{ backdropFilter: 'blur(4px)' }}>
                <div >
                  <div div className=' container  px-md-5  px-0 py-5 ' >
                    <div className="text-start text-white position-relative">
                      <h1 className='mb-3 card-hover-heading' style={{ color: 'rgb(255, 162, 0)' }}><b>Parts Management:</b></h1>
                      <h5 className='text_justifly pb-3 card-hover-text  '  ><b style={{ color: 'rgb(255, 162, 0)' }}> Rowth BMG</b> <b> deals in all type of Automobile spare parts inventory management which involves - overseeing the procurement – storage – tracking & distribution of spare parts to ensure availability when needed and minimize costs associated with excess or insufficient inventory. We deal right from small, medium & premium car industry to Heavy construction & mining equipment spare parts of any type, shape or model.</b></h5>
                      <h5 className='text_justifly pb-3 card-hover-para card_para' style={{ wordSpacing: '8px' }}  ><b>We through our global presence and direct association with all the major & small automobile brands and there distribution network globally efficiently satisfy our customer base globally. We deals in 3rd party manufacturing & supply chain management for all brand Genuine & After-Market spare parts at the cheapest pricing.</b></h5>


                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${activeTab === 2 ? "active show" : ""}`} id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab">
            <div className="">
              <img src={Equipment} alt="Slide 1" className="mobile_img" style={{ width: '100%', filter: 'brightness(75%)' }} />
              <div className=" position-absolute top-0 w-100 px-4  section_card1" style={{ backdropFilter: 'blur(4px)' }}>
                <div className="container  text-start  text-white position-relative px-md-5  px-0 ">
                  <div className='position-relative'>
                    <h1 className='mt-5 pt-md-5 pt-0 card-hover-heading' style={{ color: 'rgb(255, 162, 0)' }}><b>Construction & Mining Equipment: (Used/New)</b></h1>
                    <h5 className='text_justifly card-hover-text'><b style={{ color: 'rgb(255, 162, 0)' }}> Rowth BMG</b><b> is one stop solution to all your requirements related to Heavy Construction & Mining Equipment’s. We deal in all Brands - Used & New - Buying & Selling, anywhere in the globe. Our Global presence and direct dealing with the end consumer make us the 1st choice for all our buyers & sellers. Direct arrangements with all the big mining companies across globe make us the unique choice for all our customers.</b></h5>
                    <h5 className='text_justifly card-hover-para' style={{ lineHeight: '35px', wordSpacing: '8px' }}  > <b> Our motto</b> - <b style={{ color: 'rgb(255, 162, 0)' }}>Value for Money </b><b>is what differentiate us from the competition.</b></h5>

                  </div>
                </div>

              </div>
            </div>
          </div>
          <div className={`tab-pane fade ${activeTab === 3 ? "active show" : ""}`} id="abled-tab-pane" role="tabpanel" aria-labelledby="abled-tab">
            <div className="most ">
              <img src={india} alt="Slide 1" className="" style={{ width: '100%', height: '100%', filter: 'brightness(75%)' }} />
              <div className=" position-absolute top-0 w-100 py-5 px-4 section_card1" >
                <div className="container text-start d-flex align-items-center text-white px-md-5 px-0"  >
                  <div className='position-relative'>
                    <h1 className='my-4 card-hover-heading' style={{ color: 'rgb(255, 162, 0)' }} ><b>Make in India:</b></h1>
                    <h5 className='text_justifly card-hover-text' ><b>Make in India' initiative was launched globally in September 2014 as a part of India's renewed focus on Manufacturing with the objective to promote India as the most preferred global manufacturing destination. The 4 pillars of Make in India initiative are New Mindset, New Sectors, New Infrastructure, and New Processes.</b> </h5>
                    <h4 className='my-4 ' style={{ color: 'rgb(255, 162, 0)' }}> <b>Benefits of Make in India</b></h4>
                    <ul className='px-lg-5 px-2 fs-5' style={{ wordSpacing: '8px' }} >
                      <li className="card-hover-para"><b>Reduction in imports and increased export opportunities.</b></li>
                      <li className="card-hover-para"><b>Promotion of Indian-made products.</b></li>
                      <li className="card-hover-para"><b>Acceptability and preference to Indian made products across Globe.</b></li>
                      <li className="card-hover-para"><b>Attracts industry Incentives through Govt. various schemes to dedicated investors into manufacturing. </b></li>
                    </ul>
                    <div className="">
                      <div className="row ">
                        <div className="col">
                        <h4 className='my-md-4 my-0 card-hover-para' style={{ color: 'rgb(255, 162, 0)' }}> <b>Industry We Deal:</b></h4>
                          <h4 className='my-0 my-md-4 card-hover-para ' style={{ color: 'rgb(255, 162, 0)' }} > <b>Automobile Industry:</b></h4>
                          <h5 className='mt-md-4 mt-1 card-hover-para' > <b>Racks</b></h5>
                          <h5 className='mb-md-3 mb-2 card-hover-para' > <b>Transmission Components</b></h5>
                          <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Axle Shafts & Brakes</b></h5>
                          <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Hydraulic Components</b></h5>
                          <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Hubs & Spindles</b></h5>
                          <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Differential Housing & Parts</b></h5>
                          <h5 className='mb-md-3 mb-2 card-hover-para ' > <b>Sheet Metal Fabrication, Floors & Machining Components</b></h5>
                        </div>
                        <div className="col">
                        
                          <h4 className='my-md-4 my-0 card-hover-para' > <b>Warehousing:</b></h4>
                          <h5 className='mt-md-4 my-0 mb-3 card-hover-para ' > <b>Pallet Racking Solutions</b></h5>
                          <h5 className=' card-hover-para ' > <b>Heavy Duty Racking System</b></h5>
                        </div>




                      </div>
                    </div>



                  </div>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

    </>

  );
};

export default CardNews;

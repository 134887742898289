import React from "react";
import { BMG } from "../Assets/Images/Images";
const Process = () => {
  return (
    <div className="container process-card mb-lg-0" >
      <div className="row">
        <div className="col pt-md-5 pt-1 pt-lg-2">
   
          <div className="my-5 mb-lg-0 my-lg-2">
            <img
           
              src={BMG}
              alt="img"
              className="w-100"
              style={{ height: "auto" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Process;

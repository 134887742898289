import logo from "./logo.svg";
import "./App.css";
import Home from "./Pages/Home";
import Header from "./Components/Header";
import { BrowserRouter as Router, Route,Routes } from 'react-router-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Footer from "./Components/Footer";

function App() {
  return (
    <div className="App">
      {/* <Header /> */}
     {/* <Loading/> */}
      <Router>
        <Header />
        <Routes>
        <Route path='/' element={<Home/>} />

      

        </Routes>
        <Footer />
      </Router>
      
    </div>
  );
}

export default App;

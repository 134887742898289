
import React, { useState } from "react";
import { Row, Col, Button, Form, Container, Modal } from "react-bootstrap";
import { image8, facebook, linkedin, indiaflag, logodiff, usaflag } from "../Assets/Images/Images";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useForm } from "react-hook-form";
import emailjs from 'emailjs-com';

const Enquiry = () => {
  const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
  const [showPopup, setShowPopup] = useState(false);

  const onSubmit = data => {
    console.log(data);
    emailjs.sendForm('service_xruogxu', 'template_12t5659', '#enquiryForm', 'oTjTl4n9VjXZDtjN0')
      .then((result) => {
        console.log(result.text);
        setShowPopup(true);
        setTimeout(() => {
          setShowPopup(false);
        }, 8000);
        reset();
      }, (error) => {
        console.log(error.text);
      });
  };

  const handleClose = () => setShowPopup(false);

  const firstName = watch('firstName', '');
  const contactMethod = watch('contactMethod', []);
  return (
    <Container className="mt-lg-0 mt-3 position-relative">
      <Row>
        <Col sm={12}>
          <h1 className="text-white ms-3 text-center" >
            <b>Contact <span style={{ color: "#FFA200" }}>US</span></b>
          </h1>
        </Col>
      </Row>
      <Row className="mx-auto ">
        <div className="col-10 mx-auto">
          <div className="row">
            <Col
              sm={12}
              md={6}
              className="contact-info p-0 d-flex flex-column  shadow p-md-5 p-3"
              style={{ background: 'hsla(0, 0%, 0%, 0.2)' }}
            >
              <h3
                className="fw-bolder send-msg text-white text-md-start text-center mt-1  mb-4 mb-md-4 mb-3"
              >
                Contact info
              </h3>
              <div className="p-md-4 p-2">
                <a href="#" className="m-0 d-flex align-items-start justify-content-start mb-1" style={{ textDecoration: 'none' }}>
                  <i className="bi bi-person-fill" style={{ color: 'rgb(255, 162, 0)' }}></i>
                  <h5 className="text-white m-0 fw-bold text-base font-normal leading-6 ms-3 mb-0 pb-0">
                    Sanjay Dahuja
                  </h5>
                </a>
                <a href="tel:(+91) 99183-00001" className="m-0 mb-0 d-flex align-items-start justify-content-start mb-1" style={{ textDecoration: 'none' }}>
                  <i className="bi bi-telephone-fill" style={{ color: 'rgb(255, 162, 0)' }}></i>
                  <h5 className="text-white fw-bold text-base font-normal leading-6 ms-3 mb-0 pb-0">
                    <img src={indiaflag} className="me-1" style={{ width: '26px', height: 'auto' }} />
                    +91-99183-00001
                  </h5>
                </a>
                <a href="mailto:sanjay@rowthbmg.com" className="d-flex align-items-start justify-content-start mb-3" style={{ textDecoration: 'none' }}>
                  <i className="bi bi-envelope-fill" style={{ color: 'rgb(255, 162, 0)' }}></i>
                  <h5 className="text-white text-base font-normal leading-6 ms-3 mb-0 pb-0">
                    sanjay@rowthbmg.com
                  </h5>
                </a>
                <a href="#" className="mt-5 d-flex align-items-start justify-content-start mb-1" style={{ textDecoration: 'none' }}>
                  <i className="bi bi-person-fill" style={{ color: 'rgb(255, 162, 0)' }}></i>
                  <h5 className="text-white m-0 fw-bold text-base font-normal leading-6 ms-3 mb-0 pb-0">
                    Rob Richard
                  </h5>
                </a>
                <a href="tel:(+1) 330-659-0900" className="m-0 mb-0 d-flex align-items-start justify-content-start mb-1" style={{ textDecoration: 'none' }}>
                  <i className="bi bi-telephone-fill" style={{ color: 'rgb(255, 162, 0)' }}></i>
                  <h5 className="text-white fw-bold text-base font-normal leading-6 ms-3 mb-0 pb-0">
                    <img src={usaflag} className="me-1" style={{ width: '26px', height: 'auto' }} />
                    (+1) 330-659-0900
                  </h5>
                </a>
                <a href="mailto:r.richard@baymetal.com" className="d-flex align-items-start justify-content-start mb-5" style={{ textDecoration: 'none' }}>
                  <i className="bi bi-envelope-fill" style={{ color: 'rgb(255, 162, 0)' }}></i>
                  <h5 className="text-white text-base font-normal leading-6 ms-3 mb-0 pb-0">
                    r.richard@baymetal.com
                  </h5>
                </a>
                <a href="javascript:;" className="mt-4 d-flex align-items-start justify-content-start" style={{ textDecoration: 'none' }}>
                  <i className="bi bi-geo-alt-fill" style={{ color: 'rgb(255, 162, 0)' }}></i>
                  <h5 className="text-white text-start text-base font-normal leading-6 ms-3 mb-0 pb-0">
                    <b style={{ color: 'rgb(255, 162, 0)' }}>India :</b><br />
                    <b> Rowth offshore PVT Ltd,</b><br />
                    Berkeley Square, Plot no. 24, Industrial Area Phase I, Chandigarh, India 160002
                  </h5>
                </a>
                <a href="javascript:;" className="d-flex mt-3 align-items-start justify-content-start" style={{ textDecoration: 'none' }}>
                  <i className="bi bi-geo-alt-fill" style={{ color: 'rgb(255, 162, 0)' }}></i>
                  <h5 className="text-white text-start text-base font-normal leading-6 ms-3 mb-0 pb-0">
                    <b style={{ color: 'rgb(255, 162, 0)' }}>Dubai :</b><br />
                    <b> Rowth BMG resourcesfzco</b><br />
                    Jebel Ali Free Zone, Dubai
                  </h5>
                </a>
                <a href="javascript:;" className="d-flex mt-3 align-items-start justify-content-start" style={{ textDecoration: 'none' }}>
                  <i className="bi bi-geo-alt-fill" style={{ color: 'rgb(255, 162, 0)' }}></i>
                  <h5 className="text-white text-start text-base font-normal leading-6 ms-3 mb-0 pb-0">
                    <b style={{ color: 'rgb(255, 162, 0)' }}>USA :</b><br />
                    4100 Congress Pkwy, Richfield, OH 44286
                  </h5>
                </a>
              </div>
            </Col>



            <Col md={6} sm={12} className="enquiry-form p-lg-5 py-4 shadow " style={{ background: 'hsla(0, 0%, 0%, 0.5)' }}>
              <h3
                className="fw-bolder  send-msg text-md-start text-center mb-4 fs-1"
                style={{ color: "#FFA200" }}

              >
                <b> Enquiry Form</b>
              </h3>
              <Form id="enquiryForm" onSubmit={handleSubmit(onSubmit)}>
                <Row>
                  <Col md={12} className="mt-3">
                    <Form.Group controlId="formFirstName">
                      <Form.Control
                        type="text"
                        placeholder="Name"

                        name="firstName"
                        className="rounded-pill form-input  bg-white"
                        {...register('firstName', {
                          required: true,
                          onChange: (e) => {
                            e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
                          }
                        })} />
                      {errors.firstName && <p className="text-danger text-start label-size">First name is required</p>}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} className="mt-4">
                    <Form.Group controlId="formEmail">
                      <Form.Control
                        type="email"
                        placeholder="Email"
                        name="email"
                        className="rounded-pill form-input py-3 bg-white"
                        {...register('email', { required: true })}

                      />
                      {errors.email && <p className="text-danger text-start label-size">Email is required</p>}
                    </Form.Group>
                  </Col>
                  <Col md={12} className="mt-4">
                    <Form.Group controlId="formPhone">
                      <Form.Control
                        type="tel"
                        placeholder="Phone"
                        name="phone"
                        className="rounded-pill form-input py-3 bg-white"
                        {...register('phone', {
                          required: true,
                          pattern: /^\d{10}$/,
                        })}
                        onKeyPress={(e) => {
                          if (!/[0-9]/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                      />
                      {errors.phone && (
                        <p className="text-danger text-start label-size">
                          Phone must be exactly 10 digits
                        </p>
                      )}
                    </Form.Group>

                  </Col>
                  <Col sm={12} className="mt-4">
                    <Form.Group>
                      {/* <Form.Label className="text-white text-start label-size">Preferred method of communication</Form.Label> */}
                      <div className="d-flex align-items-center text-white" >
                        <Form.Check
                          type="radio"
                          label="Email"
                          name="contactMethod"
                          id="email"
                          value="email"
                          {...register('contactMethod', { required: true })}

                        />
                        <Form.Check
                          type="radio"
                          label="Phone"
                          name="contactMethod"
                          id="phone"
                          value="phone"
                          className="ms-5"
                          {...register('contactMethod', { required: true })}

                        />
                      </div>
                      {errors.contactMethod && <p className="text-danger text-start label-size">Please select a preferred method of communication</p>}
                    </Form.Group>
                  </Col>
                </Row>
                <Form.Group controlId="formMessage" className="mt-4">
                  <Form.Control
                    as="textarea"
                    rows={1}
                    placeholder="Message"
                    name="message"
                    className="rounded-pill form-input  bg-white py-3 py-lg-3"
                    {...register('message', { required: true })}

                  />
                  {errors.message && <p className="text-danger text-start label-size" >Message is required</p>}
                </Form.Group>
                <div className="col-12 d-flex align-items-center justify-content-start mt-3">
                  <Button
                    type="submit"
                    className="mt-3 mb-2  form-input border-0 py-2 p-lg-0 py-lg-3 px-3 w-100 rounded-pill"
                    style={{ backgroundColor: "#FFA200" }}
                  >
                    Send Message
                  </Button>
                </div>
              </Form>
              <Modal show={showPopup} onHide={handleClose} centered>
                <Modal.Body className="text-center">
                  <div className="text-success mb-4">
                    <i className="fas fa-check-circle fa-3x"></i>
                  </div>
                  <h4>Thank You!</h4>
                  <p>Your details have been successfully submitted. Thanks!</p>
                  <Button variant="success" onClick={handleClose}>OK</Button>
                </Modal.Body>
              </Modal>
            </Col>
          </div>
        </div>
      </Row>
    </Container>
  );
};

export default Enquiry;

import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-scroll";
import { useNavigate } from "react-router";
import { Logo, logo1, logodiff, offshore, indiaflag } from "../Assets/Images/Images";
const Footer = () => {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate("/");
  };
  return (
    <div className="footer-section mt-5 shadow"
      //  style={{background:'hsla(0, 0%, 0%, 0.5)'}}
      style={{ backdropFilter: 'blur(25px)' }}
    >
      <div class="col-12 pt-md-4">
      <div className="container px-0">
      <div className="row m-0 ">
          <div className="col-12 mt-lg-0 mt-4 px-0">
          
          </div>

        </div>
      </div>
        <div class="container mx-auto pt-1">

          <div class="row py-2 py-lg-4  add_topBottom_border d-flex align-items-start justify-content-start mb-2 mb-lg-0" >
            <div className="col-lg-4 col-12 mb-lg-0 mb-4 px-5 px-lg-0 text-white text-start">
            <div className="text-md-start text-center mt-3 mt-lg-0">
              <img
                src={offshore}
                className="responsive_logo "
              />
            </div>
            </div>
            <div className="col-lg-4 col-12 mb-lg-0 mb-4 px-5 px-lg-0 text-white text-start">
            <h4 className="mb-lg-3" style={{ color: "#FFA200" }}><b>Our Services</b></h4>
              <p class="mb-lg-2 px-lg-0 px-2 mb-md-2 mb-2 ">
                <a
                  class="nav-link  email_data_para p-0 ms-0"
                
                >
                  {" "}
                  Asset Management
                </a>
              </p>
              <p class="mb-lg-2 mb-md-2 px-lg-0 px-2 mb-2 ">
                <a
                  class="nav-link  email_data_para p-0 ms-0"
               
                >
                  {" "}
                  Parts Management
                </a>
              </p>
              <p class="mb-lg-2 px-2 px-lg-0 mb-md-2 mb-2 ">
                <a
                  class="nav-link  email_data_para p-0 ms-0"
               
                >
                  Construction & Mining Equipment
                </a>
              </p>
              <p class="mb-lg-2 px-2 px-lg-0 mb-md-2 mb-2 ">
                <a class="nav-link  email_data_para p-0 ms-0 cp">
                  {" "}
                  Make in India
                </a>
              </p>
        
            </div>
            <div className="col-lg-4 col-12 mb-lg-0 mb-4 px-5 px-lg-0 text-white  text-start">
              {/* <h4 className="mb-3" style={{ color: "#FFA200" }}><b>Contact info</b></h4> */}
              {/* <div className="col-12 mb-2 ">
                <div className="row">

                  <div class="col-2 pe-0 p-0 px-0 d-flex align-items-start justify-content-center">
                    <i
                      class="bi bi-telephone-fill"
                      style={{ color: "rgb(255, 162, 0)", fontSize: "26px" }}
                    ></i>
                  </div>
                  <div class="col-10  ps-0  text-white">

                    <a
                      href="tel:(+91) 99183-00001"
                      class="nav-link  email_data p-0 m-0"
                      rel="noreferrer"

                      target="_blank"
                    >
                      <img
                        src={indiaflag}
                        className="me-1
                      "
                        style={{ width: "26px", height: "auto" }}
                      />
                      {" "} +91-99183-00001
                    </a>
                  </div>
                  <div class="col-2 p-0 px-0 pe-0 d-flex align-items-start justify-content-center">
                    <i
                      class="bi bi-envelope-fill"
                      style={{ color: "rgb(255, 162, 0)", fontSize: "26px" }}
                    ></i>
                  </div>
                  <div class="col-10 ps-0  text-white">

                    <a
                      class="nav-link  email_data p-0 m-0"
                      rel="noreferrer"
                      href="mailto:help@mooverglobal.com"
                      target="_blank"
                    >
                      sanjay@rowthbmg.com{" "}
                    </a>
                  </div>
                </div>
              </div> */}
              {/* <div className="col-12">
                <div className="row">
                  <div class="col-2 p-0 px-0 pe-0 d-flex align-items-start justify-content-center">
                    <i
                      class="bi bi-geo-alt-fill"
                      style={{ color: "rgb(255, 162, 0)", fontSize: "26px" }}
                    ></i>
                  </div>
                  <div class="col-10 ps-0  text-white  d-flex align-items-center ">

                    <p class="m-0 p-0 email_data">
                      Rowth offshore PVT Ltd ,<br />
                      Berkeley Square, Plot no. 24, Industrial Area Phase I,
                      Chandigarh,India 160002
                    </p>
                  </div>
                </div>
              </div> */}
              {/* <div class="col-12 px-3 text-center  mt-3 d-lg-flex align-items-lg-start justify-content-lg-start">
                <a href="#">
                  {" "}
                  <i class="bi bi-facebook fs-4  me-3 " style={{ color: 'white' }} ></i>
                </a>
                <a href="#">
                  {" "}
                  <i class="bi bi-linkedin fs-4  rounded-5 shadow" style={{ color: 'white' }}></i>
                </a>
              </div> */}
                  <h4 className="mb-lg-3" style={{ color: "#FFA200" }}><b>Quick Link</b></h4>
              <p class="mb-lg-2 px-2 px-lg-0 mb-md-2 mb-2">
              <Link
              
                offset={-60}
                spy={true}
                to="home"
                onClick={redirectToHome}
                smooth={true}
                duration={400}
                ignoreCancelEvents={true}
              >
                Home
              </Link>
              </p>
              <p class="mb-lg-2 px-2 px-lg-0 mb-md-2 mb-2 ">
              <Link
                offset={-60}
                to="service"
                spy={true}
                onClick={redirectToHome}
                smooth={true}
                duration={400}
                ignoreCancelEvents={true}
              >
                Our Services
              </Link>
              </p>
              <p class="mb-lg-2 px-2 px-lg-0 mb-md-2 mb-2 ">
              <Link
                offset={-60}
                to="partner"
                spy={true}
                onClick={redirectToHome}
                smooth={true}
                duration={400}
                ignoreCancelEvents={true}
              >
                Partner Company
              </Link>
              </p>
              <p class="mb-lg-2 px-2 px-lg-0 mb-md-2 mb-2 ">
            
              <Link
                offset={-60}
            
                to="aboutUs"
                spy={true}
                onClick={redirectToHome}
                smooth={true}
                duration={400}
                ignoreCancelEvents={true}
              >
                About Us
              </Link>
          
              </p>
              <p class="mb-lg-2 px-2 px-lg-0 mb-md-2 mb-2 ">
             
              <Link
            
                offset={-70}
         
                to="contactUs"
             
                onClick={redirectToHome}
                smooth={true}
                duration={400}
                ignoreCancelEvents={true}
              >
                Contact Us
              </Link>
         
              </p>
            </div>

          </div>



          <div class="copyright-area" style={{ backdropFilter: 'blur(10px)' }}>
            <div class="container">
              <div class="row">
                <div class="col-xl-12 col-lg-12 text-center text-lg-left">
                  <div class="copyright-text text-white fw-bold">
                    <p>© 2024 <b style={{ color: "#FFA200" }}>Rowth BMG</b>. All rights reserved. </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

import React from "react";
import { image6, JCB, aboutimg } from "../Assets/Images/Images";
import { Container, Row, Col } from "react-bootstrap";

const Aboutus = () => {
  const mapImages = [
    { image: image6 },
    { image: image6 },
    { image: image6 },
    { image: image6 },

  ];

  return (
    <>
      <div className="my-4 py-3" style={{ backgroundColor: "#FCEDD5" }}>
        <div className="container ">
          <div className="row  position-relative rounded-5 text-white "
            // style={{backdropFilter:'blur(30px)'}}
            style={{ background: 'hsla(0, 0%, 0%, 0.5)' }}
          >
               <div className="col-12 col-md-6 d-lg-none  px-0 ">
              <img className="w-100 h-100 img_shadow rounded-5 " src={aboutimg} alt="map" />
            </div>
            <div className="col-12 col-md-6 px-4 py-5 ">
              <div className=" align-items-center  text-center ms-4  ">
                <h1 className="mb-2 text-center ">

                  <b className=" text-shadow" style={{ color: "#FFA200" }} >About Us{" "}</b>

                </h1>
              </div>
              <div
                className="w-100 h-0
                   border-bottom border-warning border-2"
              ></div>
              <h4 className="text-start lh-lg d-flex mb-0 pt-4 px-4 alltext-justify mb-0" style={{ color: "#FFA200" }} ><b>End to End Supply Chain Management Solution </b></h4>
              <p
                className="text-start lh-lg d-flex mb-0  px-4 alltext-justify mb-2"
                style={{ wordSpacing: "5px" }}
              >
                Rowth BMG handles the entire production flow of goods or services - Starting from the raw components to delivering the final product to consumers. Follow the 5 Components of SCM --We Plan- We source – We make – We Deliver – We Return.
              </p>
              <p className="text-start lh-lg d-flex mb-0  px-4 alltext-justify"
                style={{ wordSpacing: "5px" }}>We focus the 7 C's — Connect - Create - Customize – coordinate – consolidate – Collaborate & contribute. This  represent a holistic approach to managing logistics processes. Implementing advanced technology for the supply chain is crucial in this process, providing tools for data collection, process optimization, and continuous performance measurement. SCM includes Supplier relationship, customer relationship, information sharing, information quality & postponement which have an indirect impact on competitive advantage through supply chain responsiveness </p>
              <div className="w-100 h-0  mt-2 border-bottom border-warning border-2"></div>
            </div>
            <div className="col-12 col-md-6 d-none d-lg-block  px-0 ">
              <img className="w-100 h-100 img_shadow rounded-5 " src={aboutimg} alt="map" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Aboutus;

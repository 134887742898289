import React from "react";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-scroll";
import { useNavigate } from "react-router";
import {
  offshore,
  indiaflag,
  usaflag,
  mailicon

} from "../Assets/Images/Images";

function Header() {
  const navigate = useNavigate();

  const redirectToHome = () => {
    navigate("/");
  };

  return (
    <Navbar collapseOnSelect expand="lg" className="py-0 w-100 nav" fixed="top">
      <Container className="d-flex justify-content-between align-items-center px-0">
        <Navbar.Brand href="#home" className="py-0">
          <img
            src={offshore}
            alt="Rowth Logo"
            className="header-logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mx-auto">
            <Nav.Item>
              <Link
                activeClass="active"
                className="hover_effect nav-text"
                offset={-60}
                spy={true}
                to="home"
                onClick={redirectToHome}
                smooth={true}
                duration={400}
                ignoreCancelEvents={true}
              >
                Home
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                activeClass="active"
                offset={-60}
                className="hover_effect nav-text"
                to="service"
                spy={true}
                onClick={redirectToHome}
                smooth={true}
                duration={400}
                ignoreCancelEvents={true}
              >
                Our Services
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                activeClass="active"
                offset={-60}
                className="hover_effect nav-text"
                to="partner"
                spy={true}
                onClick={redirectToHome}
                smooth={true}
                duration={400}
                ignoreCancelEvents={true}
              >
                Partner Company
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                activeClass="active"
                offset={-60}
                className="hover_effect nav-text"
                to="aboutUs"
                spy={true}
                onClick={redirectToHome}
                smooth={true}
                duration={400}
                ignoreCancelEvents={true}
              >
                About Us
              </Link>
            </Nav.Item>
            <Nav.Item>
              <Link
                activeClass="active"
                offset={-70}
                className="hover_effect nav-text"
                to="contactUs"
                spy={true}
                onClick={redirectToHome}
                smooth={true}
                duration={400}
                ignoreCancelEvents={true}
              >
                Contact Us
              </Link>
            </Nav.Item>
          </Nav>
          <div className="d-flex align-items-center justify-content-center  text-center text-light">
            <div className="row">
            
              <div className=" px-0 d-flex justify-content-center align-items-center flex-column text-black">
                <div className="col-12  d-flex align-items-center justify-content-start pb-1">
                  <img
                    src={indiaflag}
                    className="me-1 ps-1"
                    style={{ width: "32px", height: "auto" }}
                  />
                  <a className="text-decoration-none  text-dark-white con " href="tel:(+91) 99183-00001">
                  <b>  (+91) 99183-00001</b>
                  </a>
                </div>
                <div className="col-12  d-flex align-items-center justify-content-start pb-1">
 
                  <img    src={ usaflag}
                    className="me-1 ps-1"
                    style={{ width: "32px", height: "auto" }}
                  />
                   <a className="text-decoration-none  text-dark-white con" href="tel:(+) 1330-659-0900">
                  <b>  (+1) 330-659-0900</b>
                  </a>
                </div>
                <div className="col-12 d-flex align-items-center justify-content-start text-white">
                {/* <i className="bi bi-envelope-fill  fs-4 me-1" style={{ color: "rgb(255, 162, 0)" }}></i>{" "}{" "} */}
                <img    src={mailicon}
                    className="me-1 ps-1"
                    style={{ width: "32px", height: "auto" }}
                  />
                  <a className="mb-0 pb-0 text-decoration-none text-dark-white con" href="mailto:sanjay@rowthbmg.com">
                      <b>info@rowthbmg.com</b>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </Navbar.Collapse>
      </Container>
      <div className="progress-bar"></div>
    </Navbar>
  );
}

export default Header;

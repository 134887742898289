import React, { useState, useEffect } from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Container, Nav, Navbar } from "react-bootstrap";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import {
  Logo,
  logo1,
  komastu,
  indiaflag,
  logodiff,
  logosame,
  logobottom,
  ban1,
  ban2,
  ban3,
  ban4,
  ban5,
  ban6,
  offshore
} from "../Assets/Images/Images";
// import Services from "./Services";
import Aboutus from "./Aboutus";
import Achievements from "./Achievements";
import Enquiry from "./enquiry";
import Partners from "./partners";
import Process from "./Process";
import CardNews from "./CardNews";
import { Link, Element } from "react-scroll";

const Home = () => {
  const [textIndex, setTextIndex] = useState(0);
  const texts = [
    {
      data: "Welcome to Rowth Offshore",
      heading: "End-to-End Supply Chain Management",
      text: "",
      next: "",
      any: "",
      every: "to everything",
    },
    {
      // data: "Welcome to",
      heading: "We're the complete solution",
      next: "to the construction and mining industry",
      any: " Auto mobile parts management.",
      every: "Make in India",
    },
  ];

  // console.log(activeSection);
  return (
    <div className=" px-0 w-100">

      <Element name="home">
        <div id="home">

          <div className=" mainDiv  d-flex align-items-center "
            style={{ margin: '0' }}
          >
          </div>
          <div className=" container d-flex align-items-center  home-content-height " >
            <div className="position-relative mb-3 mb-lg-0 m-0">
              <h4 className="text-start font_style text-white mt-4 mt-lg-5">
                Welcome to <img src={offshore} style={{ width: '150px' }} alt="Logo" />
              </h4>

              <h1 className="mb-0 font_style col-12 col-md-12 text-white text-start had_size">
                <b>Rowth BMG provides complete solutions to supply chain management - Anything To Everything</b>
              </h1>

              <div className="p-3 p-lg-4 mt-4 mt-lg-4 mb-0">
                <h3 className="mb-3 text-start text-white list-heading">
                  <i className="bi bi-check2-circle fs-4 fs-lg-3 mx-2" style={{ color: "rgb(255, 162, 0)" }}></i>
                  <span>Asset Management</span>
                </h3>

                <h3 className="mb-3 text-start text-white list-heading">
                  <i className="bi bi-check2-circle fs-4 fs-lg-3 mx-2" style={{ color: "rgb(255, 162, 0)" }}></i>
                  <span>Parts Management</span>
                </h3>

                <h3 className="mb-3 text-start text-white list-heading">
                  <i className="bi bi-check2-circle fs-4 fs-lg-3 mx-2" style={{ color: "rgb(255, 162, 0)" }}></i>
                  <span>Construction & Mining Equipment</span>
                </h3>

                <h3 className="mb-3 text-start text-white list-heading">
                  <i className="bi bi-check2-circle fs-4 fs-lg-3 mx-2" style={{ color: "rgb(255, 162, 0)" }}></i>
                  <span>Make in India</span>
                </h3>
              </div>
            </div>

          </div>

          <div className="row ">
            <div
              className="col mb-5  py-lg-5 m-md-0 m-sm-0  px-0 position-absolute shadow"

              id="services"
              style={{ backdropFilter: 'blur(2px)' }}
            >
              <Container className="pe-5 mt-0 row mx-auto position-relative  " >

                <div className="col-12 text-start py-2 py-lg-0 d-flex align-items-center ">
                  <div>
                    <h1
                      className="mb-0  text-start font_style fw-bold  text-secondary px-4 secnd_had"
                    >
                      <span style={{ color: "#FFA200" }} className="fw-bolder">
                        Avant  Garde of  business
                      </span>
                    </h1>
                    <p className="mb-1  text-white text-start scnd_para  px-4">
                      <b>With our skills in supply chain management, we redefined success globally. Opt for Rowth BMG <span style={{ color: "#FFA200" }} > -Always deliver more than expected.</span></b>
                    </p>
                  </div>


                </div>
              </Container>
            </div>
          </div>
        </div>
      </Element>
      <Element name="service" className=" position-relative bg-transparent margin_top" style={{ background: 'rgb(243 243 243)' }}>
        <div id="services">
          <CardNews />
          <Process />
          <Achievements />
        </div>
      </Element>
      <Element name="partner">
        {" "}
        <div id="partners">
          <Partners />
        </div>
      </Element>
      <Element name="aboutUs">
        {" "}
        <div id="aboutus">
          <Aboutus />
        </div>
      </Element>
      <Element name="contactUs" >
        <div id="enquiry">
          <Enquiry />
        </div>
      </Element>


    </div>
  );
};

export default Home;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { image7 } from "../Assets/Images/Images"; // Make sure the import path is correct
import { MdFolderOpen } from "react-icons/md";
import { VscGraph } from "react-icons/vsc";
import { FaRegUserCircle } from "react-icons/fa";
import { FaRegFaceSmileBeam } from "react-icons/fa6";
import { FaChalkboardTeacher } from "react-icons/fa";
import { LuAreaChart } from "react-icons/lu";
import { RiCustomerService2Line } from "react-icons/ri";

const Achievements = () => {
  const choose = [
    {
      icon: (
        <FaChalkboardTeacher
          className="text-black"
          style={{ fontSize: "4rem" }}
        />
      ),
      heading: "Ethical Business Practices",
      text: "ROWTH BMG upholds the highest standards of ethical business practices, ensuring integrity and transparency in all operations.",
    },
    {
      icon: <LuAreaChart className="text-black" style={{ fontSize: "4rem" }} />,
      heading: "Global Operations and Logistics",
      text: "With local management, ROWTH BMG provides logistics,transportation, customs brokering services for  cross-border operations.",
    },
    {
      icon: <VscGraph className="text-black" style={{ fontSize: "4rem" }} />,
      heading: "Sustainable SolutionsParts Management",
      text: "ROWTH BMG recycles metals responsibly,providing top upfront payments for excess and obsolescence  rebates.",
    },
    {
      icon: (
        <RiCustomerService2Line
          className="text-black"
          style={{ fontSize: "4rem" }}
        />
      ),
      heading: "Centralized Customer Services  ",
      text: "ROWTH BMG boasts centralised customer services team that goes beyond traditional support to ensure  efficient experience for clients.",
    },
    // Add more items as needed
  ];

  return (
    <>
      <Container
        fluid
        className=" shadow  position-relative"
     
         style={{ backdropFilter:'blur(10px)'}}
      >
        <Row className="achivment" >
          <Col sm={12}>
            <Row>
              <Col sm={12} className="text-center mb-4">
                <h1
                  className="mb-0 mt-4 text-white"
                  style={{ fontWeight: "100" }}
                >
                  <b>    Our{" "}
                  <span style={{ color: "#FFA200" }} className="fw-bolder">
                    Achievements
                  </span>{" "}</b>
               
                </h1>
              </Col>
            </Row>
            <Container>
              <Row className="h-100 mt-5">
                <Col
                  md={3}
                  sm={6}
                  className="d-flex justify-content-center cursor-pointer align-items-center flex-column achievement-item"
                >
                  <MdFolderOpen
                    style={{ color: "#FFA200", fontSize: "4rem" }}
                    className=" fw-bolder achievement-icon"
                  />
                  <p
                    className="mt-2 fs-3 fw-bolder achievement-number"
                    style={{ color: "#FFA200" }}
                  >
                    2000+
                  </p>
                  <p className="text-center text-white fs-3 fw-bold">Support Threads</p>
                </Col>
                <Col
                  md={3}
                  sm={6}
                  className="d-flex justify-content-center cursor-pointer align-items-center flex-column achievement-item"
                >
                  <VscGraph
                    style={{ color: "#FFA200", fontSize: "4rem" }}
                    className=" fw-bolder achievement-icon"
                  />
                  <p
                    className="mt-2 fs-3 fw-bolder achievement-number"
                    style={{ color: "#FFA200" }}
                  >
                    3500+
                  </p>
                  <p className="text-center text-white fs-3 fw-bold">Queries Solved</p>
                </Col>
                <Col
                  md={3}
                  sm={6}
                  className="d-flex justify-content-center cursor-pointer align-items-center flex-column achievement-item"
                >
                  <FaRegUserCircle
                    style={{ color: "#FFA200", fontSize: "4rem" }}
                    className=" fw-bolder achievement-icon"
                  />
                  <p
                    className="mt-2 fs-3 fw-bolder achievement-number"
                    style={{ color: "#FFA200" }}
                  >
                    1000+
                  </p>
                  <p className="text-center text-white fs-3 fw-bold">Registered Users</p>
                </Col>
                <Col
                  md={3}
                  sm={6}
                  className="d-flex justify-content-center cursor-pointer align-items-center flex-column achievement-item"
                >
                  <FaRegFaceSmileBeam
                    style={{ color: "#FFA200", fontSize: "4rem" }}
                    className=" fw-bolder achievement-icon"
                  />
                  <p
                    className="mt-2 fs-3 fw-bolder achievement-number"
                    style={{ color: "#FFA200" }}
                  >
                    2000+
                  </p>
                  <p className="text-center text-white fs-3 fw-bold">Happy Customers</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row className="achivment2" style={{backdropFilter: 'blur(2px)'  }}>
          <Col sm={12}>
            <Row>
              <Col sm={12} className="text-center mb-5">
                <h1 
                  className="mb-0 mt-4  text-white "
                  style={{ fontWeight: "100" }}
                >
                  <b>
                  Why{" "}
                  <span style={{ color: "#FFA200" }} className="fw-bolder">
                    Choose Us
                  </span>{" "}
                  </b>
                 
                </h1>
                <p
                  className="mb-0 fs-5 text-white"
                  style={{ fontWeight: "100" }}
                >
                <b>  Most Helpful Web Search</b>
                </p>
              </Col>
            </Row>
            <Container>
              <Row className="h-100">
                {choose.map((item, index) => (
                  <Col
                    key={index}
                    md={3}
                    sm={6}
                    className="d-flex justify-content-center cursor-pointer align-items-center flex-column choose-item"
                  >
                    {item.icon}
                    <h6
                      className="mt-2   fw-bold choose-heading"
                      style={{ color: "#FFA200" }}
                    >
                      {item.heading}
                    </h6>
                    <p className="text-center text-white  choose-text">{item.text}</p>
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      {/* <Container
        fluid
        className="d-md-none d-block"
        style={{
          backgroundImage: `url(${image7})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundAttachment: "fixed",
        }}
      >
        <Row style={{ background: "rgba(252, 237, 213, 0.5)" }}>
          <Col sm={12}>
            <Row>
              <Col sm={12} className="text-center">
                <p
                  className="mb-0 mt-4 fs-1 text-secondary"
                  style={{ fontWeight: "100" }}
                >
                  Our{" "}
                  <span style={{ color: "#FFA200" }} className="fw-bolder">
                    Achievements
                  </span>{" "}
                </p>
              </Col>
            </Row>
            <Container>
              <Row>
                <Col
                  md={3}
                  sm={6}
                  className="d-flex justify-content-center cursor-pointer align-items-center flex-column achievement-item"
                >
                  <MdFolderOpen
                    style={{ color: "#FFA200", fontSize: "4rem" }}
                    className=" fw-bolder achievement-icon"
                  />
                  <p
                    className="mt-2 fs-3 fw-bolder achievement-number"
                    style={{ color: "#FFA200" }}
                  >
                    2000+
                  </p>
                  <p className="text-center fs-3 fw-bold">Support Threads</p>
                </Col>
                <Col
                  md={3}
                  sm={6}
                  className="d-flex justify-content-center cursor-pointer align-items-center flex-column achievement-item"
                >
                  <VscGraph
                    style={{ color: "#FFA200", fontSize: "4rem" }}
                    className=" fw-bolder achievement-icon"
                  />
                  <p
                    className="mt-2 fs-3 fw-bolder achievement-number"
                    style={{ color: "#FFA200" }}
                  >
                    3500+
                  </p>
                  <p className="text-center fs-3 fw-bold">Queries Solved</p>
                </Col>
                <Col
                  md={3}
                  sm={6}
                  className="d-flex justify-content-center cursor-pointer align-items-center flex-column achievement-item"
                >
                  <FaRegUserCircle
                    style={{ color: "#FFA200", fontSize: "4rem" }}
                    className=" fw-bolder achievement-icon"
                  />
                  <p
                    className="mt-2 fs-3 fw-bolder achievement-number"
                    style={{ color: "#FFA200" }}
                  >
                    1000+
                  </p>
                  <p className="text-center fs-3 fw-bold">Registered Users</p>
                </Col>
                <Col
                  md={3}
                  sm={6}
                  className="d-flex justify-content-center cursor-pointer align-items-center flex-column achievement-item"
                >
                  <FaRegFaceSmileBeam
                    style={{ color: "#FFA200", fontSize: "4rem" }}
                    className=" fw-bolder achievement-icon"
                  />
                  <p
                    className="mt-2 fs-3 fw-bolder achievement-number"
                    style={{ color: "#FFA200" }}
                  >
                    2000+
                  </p>
                  <p className="text-center fs-3 fw-bold">Happy Customers</p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
        <Row style={{ backgroundColor:'transparent',backdropFilter: 'blur(2px)' }}>
          <Col sm={12} data-aos="zoom-in-up">
            <Row className="mb-4 pb-md-5 py-4">
              <Col sm={12} className="text-center">
                <p
                  className="mb-0 mt-4 fs-1 text-secondary"
                  style={{ fontWeight: "100" }}
                >
                  Why{" "}
                  <span style={{ color: "#FFA200" }} className="fw-bolder">
                    Choose Us
                  </span>{" "}
                </p>
                <p
                  className=" fs-5 text-secondary"
                  style={{ fontWeight: "100" }}
                >
                  Most Helpful Web Search
                </p>
              </Col>
            </Row>
            <Container className="pt-4" data-aos="fade-up-right">
              <Row className="h-100 mt-4 ">
                {choose.map((item, index) => (
                  <Col

                    key={index}
                    md={3}
                    sm={6}
                    className="d-flex mt-4 justify-content-center cursor-pointer align-items-center flex-column choose-item"
                  >
                    {item.icon}
                    <p
                      className="mt-2  fw-bold choose-heading"
                      style={{ color: "#FFA200" }}
                    >
                      {item.heading}
                    </p>
                    <p className="text-center choose-text text-white">{item.text}</p>
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container> */}
    </>
  );
};

export default Achievements;
